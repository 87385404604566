import moment from 'moment-timezone'
moment.tz.setDefault('Etc/UTC')

export default {
  API_URL: 'https://mcloudapi.azurewebsites.net/api/dashboard/stats/tablets_activated',
  data: {
    activatedTablets: []
  },
  selectedCountry: 'all',
  loading: true,
  error: false,
  chartInterval: 'Month',
  dates: {
    from: moment('2018-01-01').unix(),
    to: moment().unix(),
    lastSync: ''
  },
  activatedTablets: {},
  pairedModules: {},

  stats: {
    weekly: {},
    monthly: {},
    quarterly: {},
    yearly: {}
  },
  periodDescriptions: {
    weekly: '',
    monthly: '',
    quarterly: '',
    yearly: ''
  },
  previousPeriodDescriptions: {
    weekly: '',
    monthly: '',
    quarterly: '',
    yearly: ''
  },
  statsPreviousPeriod: {
    weekly: {},
    monthly: {},
    quarterly: {},
    yearly: {}
  },
  loadingNumbers: {
    weekly: true,
    monthly: true,
    quarterly: true,
    yearly: true
  }
}
