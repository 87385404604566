import moment from 'moment-timezone'
moment.tz.setDefault('Etc/UTC')

export default {
  SET_DATE_FROM: (state, obj) => {
    const date = obj.date
    state.dates.from = moment(date).unix()
  },
  SET_DATE_TO: (state, obj) => {
    const date = obj.date
    state.dates.to = moment(date).unix()
  },
  SET_LOADING: (state, bool) => {
    state.loading = bool
  },
  SET_ERROR: (state, error) => {
    state.error = error
  },
  SET_ACTIVATED_TABLETS: (state, data) => {
    state.data.activatedTablets = data
  },
  SET_COUNTRY: (state, countryCode) => {
    state.selectedCountry = countryCode
  },
  SET_CHART: (state, type) => {
    state.chartInterval = type
  },

  setStats: (state, payload) => {
    if (payload.previousPeriod) {
      state.statsPreviousPeriod[payload.period] = payload.data
    } else {
      state.stats[payload.period] = payload.data
    }
  },
  setDescription: (state, payload) => {
    if (payload.previousPeriod) {
      state.previousPeriodDescriptions[payload.period] = payload.description
    } else {
      state.periodDescriptions[payload.period] = payload.description
    }
  },
  setLoadingNumbers: (state, payload) => {
    state.loadingNumbers[payload.period] = payload.loading
  }
}
