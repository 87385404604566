import moment from 'moment-timezone'
moment.tz.setDefault('Europe/Ljubljana')

const getKeyWithHighestValue = (obj = {}) => {
  return Object.keys(obj).reduce((a, b) => obj[a] > obj[b] ? a : b, '')
}

const getInfoForPeriod = (period = 'weekly', previousPeriod = false) => {
  console.log('getInfoForPeriod(): ' + period, previousPeriod)
  let endDate = null
  let startDate = null
  let dateInfo = ''

  if (period === 'weekly') {
    startDate = moment().startOf('week')
    dateInfo = `From ${startDate.format('DD.MM.Y HH:mm:ss')}`

    if (previousPeriod) {
      dateInfo = `From ${moment().subtract(1, 'weeks').startOf('week').toDate().toLocaleDateString('sl-SI')}`
      startDate = moment().subtract(1, 'weeks').startOf('week')
      endDate = moment().subtract(1, 'weeks').endOf('week')
    }
  } else if (period === 'monthly') {
    dateInfo = moment().format('MMMM')
    startDate = moment().startOf('month')

    if (previousPeriod) {
      startDate = moment().subtract(1, 'month').startOf('month')
      endDate = moment().subtract(1, 'month').endOf('month')
    }
  } else if (period === 'quarterly') {
    dateInfo = moment(moment().quarter(), 'DD').format('Do') + ' quarter'
    startDate = moment().startOf('quarter')

    if (previousPeriod) {
      startDate = moment().subtract(1, 'quarter').startOf('quarter')
      endDate = moment().subtract(1, 'quarter').endOf('quarter')
    }
  } else if (period === 'yearly') {
    dateInfo = moment().format('y')
    startDate = moment().startOf('year')

    if (previousPeriod) {
      startDate = moment().subtract(1, 'year').startOf('year')
      endDate = moment().subtract(1, 'year').endOf('year')
    }
  }

  return {
    timestampFrom: startDate ? startDate.utc(true).unix() : startDate,
    timestampTo: endDate ? endDate.utc(true).unix() : endDate,
    dateInfo: dateInfo.toString()
  }
}

export default {
  getInfoForPeriod,
  getKeyWithHighestValue
}
