<template>
  <div>
    <v-card-actions v-show="value && description" class="text-right">
      <v-btn
        text
        small
        class="ml-auto"
        color="teal accent-4"
        @click="reveal = true"
      >
        Learn More
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <v-card
        v-if="reveal"
        class="transition-fast-in-fast-out v-card--reveal full-height h-100 d-flex flex-column"
        style="height: 100%;"
      >
        <v-card-text class="pb-0">
          {{ description }}
        </v-card-text>
        <v-spacer />
        <v-card-actions class="pt-0">
          <v-btn
            text
            small
            color="teal accent-4"
            @click="reveal = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      reveal: false
    }
  },
  props: {
    value: {
      type: [String, Number],
      default: 'monthly'
    },
    description: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
