<template>
  <v-select class="my-select-period" :value="chartFormat" @input="updateChart" :items="chartFormats" hide-details>
    <template v-slot:prepend-inner>
      <v-tooltip bottom max-width="400">
        <template v-slot:activator="{ on, attrs }">
          <v-icon slot="activator" color="white" v-bind="attrs" v-on="on">mdi-chart-bar</v-icon>
        </template>
        <span>Select period to show</span>
      </v-tooltip>
    </template>
  </v-select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => {
    return {
      chartFormats: ['Month', 'Year'] // 'Quarter'
    }
  },
  computed: {
    ...mapState({
      chartFormat: state => state.chartInterval
    })
  },
  methods: {
    updateChart (newChartVal) {
      this.$store.commit('SET_CHART', newChartVal)
      this.$store.dispatch('updateActivatedTablets')
    }
  }
}
</script>

<style lang="scss">
.my-select-period {
  max-width: 120px;
}
</style>
