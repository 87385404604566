<template>
  <div class="byCountry" style="max-width: 300px;">
    <v-simple-table height="350" fixed-header dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Country</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(count, countryCode) in byCountryFiltered" :key="countryCode" class="border-2">
            <td :class="{ 'font-bold': countryCode === 'SI' }" class="p-2">{{ countryCode }}</td>
            <td class="p-2">{{ count }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: {
    byCountry: {
      type: Object
    },
    selectedCountry: {
      type: String
    }
  },
  computed: {
    byCountryFiltered () {
      if (this.selectedCountry === 'all') {
        return this.byCountry
      }

      return Object.keys(this.byCountry)
        .filter(key => this.selectedCountry.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.byCountry[key]
          return obj
        }, {})
    }
  }
}
</script>
