var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"app"}},[_c('v-app-bar',{attrs:{"app":"","dark":""}},[_c('v-toolbar-title',[_c('img',{attrs:{"src":require('@/assets/logo.svg'),"width":this.$vuetify.breakpoint.smAndDown ? 80 : 130}}),_vm._v(" "),_c('span',{class:this.$vuetify.breakpoint.smAndDown ? 'd-none' : ''},[_vm._v("Stats")])]),_c('v-spacer'),_c('DatePicker'),_c('v-spacer'),_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"height":"100%"}},[_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":"","width":3}}),_c('CountryPicker',{staticClass:"mx-5"}),_c('ChartPicker')],1)],1),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('h1',{staticClass:"title"},[_vm._v(" Global Statistics "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"slot":"activator","color":"grey"},slot:"activator"},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Numbers not affected by filters on this page.")])])],1)]),_c('v-expansion-panel-content',[_c('GlobalStats')],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('h1',{staticClass:"title"},[_vm._v(" Activated tablets "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"slot":"activator","color":"grey"},slot:"activator"},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Tablet becomes activated when the first user logs on it (MESI, distributor, and test users are excluded). The query for this API method counts all tablets grouped by the country which were activated in the specific time period. The MESI and distributor tablets are filtered out by excluding all users which email ends with \"mesi.si\" or \"mesimedical.com\" and by excluding all users that have distributor, dev, or admin role.")])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedCountry !== 'all'),expression:"selectedCountry !== 'all'"}],staticClass:"caption mb-3"},[_vm._v(" FILTER ACTIVE! ("),_c('strong',[_vm._v("Country \""+_vm._s(_vm.selectedCountry)+"\"")]),_vm._v(": "+_vm._s(_vm.totalForCountry)+" results) ")])],1)]),_c('v-expansion-panel-content',[_c('ActivatedTablets')],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('h1',{staticClass:"title"},[_vm._v(" Paired modules "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"slot":"activator","color":"grey"},slot:"activator"},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("When the module is paired with the tablet for the first time the tablet sends that information to the cloud which then creates a new entry in the database which contains tablet-module connection data and the date when it was paired. The query for API method takes all tablet-module links which were paired in the specific time period and then counts them grouped by country. MESI and distributor tablets are filtered out the same way as for the Tablets activated method.")])])],1)]),_c('v-expansion-panel-content',[_c('PairedModules')],1)],1)],1)],1)],1),_c('v-footer',{attrs:{"app":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }