<template>
  <v-row class="">
    <v-col v-for="(description, period) in periods" :key="period" cols="6" md="3" class="d-flex justify-center">
      <NumberBlock :period="period" :descriptionMore="description" />
    </v-col>
  </v-row>
</template>

<script>
import NumberBlock from '@/components/NumberBlock'

export default {
  components: {
    NumberBlock
  },
  data () {
    return {
      periods: {
        weekly: 'Activated tablets from Monday current week.',
        monthly: 'Activated tablets this month up until today.',
        quarterly: 'Activated tablets from the beginning of the current year quarter up until today.',
        yearly: 'Activated tablets this year up until today.'
      }
    }
  }
}
</script>
