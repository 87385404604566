<template>
  <v-app id="app">
    <!--v-navigation-drawer app>
    </v-navigation-drawer-->
    <v-app-bar app dark>
      <v-toolbar-title>
        <img :src="require('@/assets/logo.svg')" :width="this.$vuetify.breakpoint.smAndDown ? 80 : 130" /> <span :class="this.$vuetify.breakpoint.smAndDown ? 'd-none' : ''">Stats</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <DatePicker />
      <v-spacer></v-spacer>
      <div class="d-flex justify-space-between align-center" style="height: 100%;">
        <v-progress-circular
          v-show="loading"
          indeterminate
          :width="3"
        ></v-progress-circular>
        <CountryPicker class="mx-5" />
        <ChartPicker />
      </div>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <v-expansion-panels multiple v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h1 class="title">
                Global Statistics
                <v-tooltip bottom max-width="400">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon slot="activator" color="grey"  v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                  </template>
                  <span>Numbers not affected by filters on this page.</span>
                </v-tooltip>
              </h1>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <GlobalStats />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h1 class="title">
                Activated tablets
                <v-tooltip bottom max-width="400">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon slot="activator" color="grey"  v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                  </template>
                  <span>Tablet becomes activated when the first user logs on it (MESI, distributor, and test users are excluded). The query for this API method counts all tablets grouped by the country which were activated in the specific time period. The MESI and distributor tablets are filtered out by excluding all users which email ends with "mesi.si" or "mesimedical.com" and by excluding all users that have distributor, dev, or admin role.</span>
                </v-tooltip>
                <span class="caption mb-3" v-show="selectedCountry !== 'all'">
                  FILTER ACTIVE! (<strong>Country "{{ selectedCountry }}"</strong>: {{ totalForCountry }} results)
                </span>
              </h1>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ActivatedTablets />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h1 class="title">
                Paired modules
                <v-tooltip bottom max-width="400">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon slot="activator" color="grey"  v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                  </template>
                  <span>When the module is paired with the tablet for the first time the tablet sends that information to the cloud which then creates a new entry in the database which contains tablet-module connection data and the date when it was paired. The query for API method takes all tablet-module links which were paired in the specific time period and then counts them grouped by country. MESI and distributor tablets are filtered out the same way as for the Tablets activated method.</span>
                </v-tooltip>
              </h1>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <PairedModules />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- If using vue-router -->
        <!--router-view></router-view-->
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import ActivatedTablets from '@/components/ActivatedTablets'
import PairedModules from '@/components/PairedModules'
import DatePicker from '@/components/DatePicker'
import CountryPicker from '@/components/CountryPicker'
import ChartPicker from '@/components/ChartPicker'
import GlobalStats from '@/components/GlobalStats'

export default {
  name: 'App',
  components: {
    ActivatedTablets, PairedModules, DatePicker, CountryPicker, ChartPicker, GlobalStats
  },
  data () {
    return {
      panel: [0, 1, 2]
    }
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
      selectedCountry: state => state.selectedCountry,
      data: state => state.data.activatedTablets
    }),
    totalForCountry () {
      if (this.selectedCountry !== 'all') {
        return this.data?.TabletsByCountry[this.selectedCountry]
      }
      return null
    }
  }
}
</script>

<style>
#app {
  background-color: var(--v-secondary-base) !important;
}
</style>
