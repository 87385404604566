<template>
  <v-sheet
    color="white"
    elevation="1"
    class="pa-5"
  >
    <div class="py-3">
      <div class="mb-3">Total in date range: {{ total }}</div>
      <!-- TODO: paired modules API does not yet return data in a format for charts such as Activated Tablets does -->
      <!--BarChart :chartdata="chartdata" :options="options" :key="barChartKey" /-->
      <div class="byCountry" style="max-width: 300px;">
        <v-simple-table height="350" fixed-header dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Country</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(count, countryCode) in byCountryFiltered" :key="countryCode" class="border-2">
                <td :class="{ 'font-bold': countryCode === 'SI' }" class="p-2">{{ countryCode }}</td>
                <td class="p-2">{{ count }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import { mapState } from 'vuex'
// import BarChart from '@/components/BarChart'

export default {
  name: 'PairedModules',
  components: {
    // BarChart
  },
  data: () => {
    return {
      loading: true,
      errored: false,
      barChartKey: 0,
      apiUrl: 'https://mcloudapi.azurewebsites.net/api/dashboard/stats/modules_paired',
      data: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          x: {
            type: 'time',
            time: {
              unit: 'month' // month, quarter, year
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      timestampFrom: state => state.dates.from,
      timestampTo: state => state.dates.to,
      selectedCountry: state => state.selectedCountry,
      intervalType: state => state.chartInterval
    }),
    byCountryFiltered () {
      if (this.selectedCountry === 'all') {
        return this.byCountry
      }

      return Object.keys(this.byCountry)
        .filter(key => this.selectedCountry.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.byCountry[key]
          return obj
        }, {})
    },
    dateFrom () {
      return this.data?.DateFrom
    },
    dateTo () {
      return this.data?.DateTo
    },
    byCountry () {
      return this.data?.ModulesByCountry
    },
    total () {
      return this.data?.TotalForPeriod
    },
    tabletsByTime () {
      return this.data?.Intervals
    },
    chartlabels () {
      return this.tabletsByTime ? Object.keys(this.tabletsByTime) : []
    },
    chartcontent () {
      if (this.tabletsByTime) {
        if (this.selectedCountry !== 'all') {
          return (Object.values(this.tabletsByTime).map((obj) => {
            return obj.TabletsByCountry[this.selectedCountry]
          }))
        }
        return (Object.values(this.tabletsByTime).map(obj => obj.TotalForPeriod))
      }
      return []
    },
    chartdata () {
      return {
        labels: this.chartlabels,
        datasets: [{
          label: this.labelLegend,
          data: this.chartcontent
        }]
      }
    },
    labelLegend () {
      let label = '# of paired modules'
      if (this.selectedCountry !== 'all') {
        label += ' in ' + this.selectedCountry
      }
      return label
    }
  },
  mounted () {
    this.getData()
  },
  watch: {
    timestampFrom () {
      this.getData()
    },
    timestampTo () {
      this.getData()
    }
  },
  methods: {
    makeAPIRequest () {
      const params = {
        timestampFrom: this.timestampFrom,
        timestampTo: this.timestampTo,
        intervalType: this.intervalType
      }

      this.axios
        .get(this.apiUrl, { params })
        .then(response => {
          this.data = response.data
        })
        .catch(error => {
          console.log('Error from makeAPIRequest: ', error)
          this.errored = true
        })
        .finally(() => { this.loading = false })
    },
    getData () {
      this.makeAPIRequest()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
