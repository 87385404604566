<template>
  <v-sheet
    color="white"
    elevation="1"
    class="pa-5"
  >
    <div class="py-3">
      <div class="mb-1">Total in date range: {{ total }} (SI: {{ totalInSLO }} - {{ sloPercent }}%) </div>
      <div class="d-md-flex">
        <BarChart class="flex-grow-1 order-md-2" :chartdata="chartdata" :options="options" :key="barChartKey" />
        <ByCountryTable class="mt-5 pr-md-5" :selected-country="selectedCountry" :by-country="byCountry" />
      </div>
    </div>
  </v-sheet>
</template>

<script>
import { mapState } from 'vuex'
import BarChart from '@/components/BarChart'
import ByCountryTable from '@/components/ByCountryTable'

export default {
  name: 'ActivatedTablets',
  components: {
    BarChart, ByCountryTable
  },
  data: () => {
    return {
      barChartKey: 0,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          x: {
            type: 'time',
            time: {
              unit: 'month' // month, quarter, year
            }
          }
        }
      }
    }
  },
  computed: {
    chartlabels () {
      return this.tabletsByTime ? Object.keys(this.tabletsByTime) : []
    },
    chartcontent () {
      if (this.tabletsByTime) {
        if (this.selectedCountry !== 'all') {
          return (Object.values(this.tabletsByTime).map((obj) => {
            return obj.TabletsByCountry[this.selectedCountry]
          }))
        }
        return (Object.values(this.tabletsByTime).map(obj => obj.TotalForPeriod))
      }
      return []
    },
    byCountry () {
      return this.data?.TabletsByCountry
    },
    chartdata () {
      return {
        labels: this.chartlabels,
        datasets: [{
          label: this.labelLegend,
          data: this.chartcontent
        }]
      }
    },
    labelLegend () {
      let label = '# of activated tablets'
      if (this.selectedCountry !== 'all') {
        label += ' in ' + this.selectedCountry
      }
      return label
    },
    tabletsByTime () {
      return this.data?.Intervals
    },
    dateFrom () {
      return this.data?.DateFrom
    },
    dateTo () {
      return this.data?.DateTo
    },
    total () {
      return this.data?.TotalForPeriod
    },
    totalInSLO () {
      return this.data?.TabletsByCountry?.SI ?? 0
    },
    sloPercent () {
      return Math.round(this.totalInSLO / this.total * 100)
    },
    ...mapState({
      dateFromTimestamp: state => state.dates.from,
      dateToTimestamp: state => state.dates.to,
      loading: state => state.loading,
      selectedCountry: state => state.selectedCountry,
      data: state => state.data.activatedTablets
    })
  },
  mounted () {
    this.getData()
  },
  updated () {
    // force re-render the BarChart component by changing it's key
    // this.barChartKey++
  },
  watch: {
    dateFromTimestamp () {
      this.getData()
    },
    dateToTimestamp () {
      this.getData()
    },
    chartdata () {
      this.barChartKey++
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('updateActivatedTablets')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
