<template>
  <v-card width="100%" max-width="400px" class="position-relative">
    <v-card-text class="pb-2 d-flex flex-column">
      <v-progress-circular
        v-if="currentLoading"
        indeterminate
        :size="30"
        :width="3"
      ></v-progress-circular>
      <div v-else>
        <div class="d-flex justify-space-between align-end">
          <div class="text-h5 text-lg-h4 text-capitalize">{{ period }}</div>
          <div class="text-h5 text-lg-h2" :class="currentHasMore ? 'primary--text': ''">{{ value }}</div>
        </div>
        <div class="d-flex justify-space-between align-start">
          <div class="caption" v-show="description">{{ description }}</div>
          <div>Previous: <span class="title">{{ valuePreviousPeriod }}</span></div>
        </div>
      </div>
      <v-divider class="my-2" />
      <div class="d-flex align-center" v-if="countryCode">
        <div v-if="flagUrl">
          <v-img :src="flagUrl" width="70" :alt="countryTitleAlt" :title="countryTitleAlt" />
        </div>
        <div class="px-3"><span class="font-weight-bold title text-uppercase">{{ countryCode }}</span><span class="text-lowercase title font-weight-regular"> leads</span></div>
      </div>
    </v-card-text>
    <CardActions :value="value" :description="descriptionMore" />
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import helpers from '@/helpers/stat-helpers'
import CardActions from '@/components/CardActions'

export default {
  components: {
    CardActions
  },
  props: {
    period: {
      type: String,
      default: 'monthly'
    },
    descriptionMore: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState([
      'stats', 'loading', 'statsPreviousPeriod', 'periodDescriptions', 'previousPeriodDescriptions'
    ]),
    value () {
      return this.currentPeriodStats.TotalForPeriod
    },
    valuePreviousPeriod () {
      return this.previousPeriodStats.TotalForPeriod
    },
    currentHasMore () {
      return this.value > this.valuePreviousPeriod
    },
    description () {
      return this.periodDescriptions[this.period]
    },
    descriptionPrevious () {
      return this.previousPeriodDescriptions[this.period]
    },
    countryCode () {
      let countryMostActivated = ''
      if (this.currentPeriodStats?.TabletsByCountry) {
        countryMostActivated = helpers.getKeyWithHighestValue(this.currentPeriodStats?.TabletsByCountry)
      }
      return countryMostActivated
    },
    currentPeriodStats () {
      return this.stats[this.period]
    },
    previousPeriodStats () {
      return this.statsPreviousPeriod[this.period]
    },
    currentLoading () {
      return this.loading[this.period]
    },
    flagUrl () {
      if (this.countryCode) {
        return `https://flagcdn.com/w80/${this.countryCode.toLowerCase()}.png`
        // return `https://www.countryflags.io/${this.countryCode.toLowerCase()}/flat/64.png`
      }
      return ''
    },
    countryTitleAlt () {
      return `Country code: ${this.countryCode}`
    }
  },
  data () {
    return {
      reveal: false
    }
  },
  mounted () {
    this.getmTabletStats(this.period)
    this.getmTabletStats(this.period, true)
  },
  methods: {
    getmTabletStats (period = 'monthly', previousPeriod = false) {
      const { timestampFrom, timestampTo, dateInfo } = helpers.getInfoForPeriod(period, previousPeriod)
      console.log(timestampFrom, timestampTo)
      this.$store.dispatch('getStats', { period, timestampFrom, timestampTo, previousPeriod: previousPeriod, description: dateInfo })
    }
  }
}
</script>

<style lang="scss">
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
