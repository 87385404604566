<template>
  <v-row class="justify-center">
    <v-col cols="6" md="4">
      <v-menu
        ref="menuFrom"
        v-model="menuFrom"
        :close-on-content-click="false"
        @input="onMenuFromToggle"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formattedFrom"
            placeholder="Date from"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            hide-details
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dateFromISO"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menuFrom = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menuFrom.save(dateFromISO)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="6" md="4">
      <v-menu
        ref="menuTo"
        v-model="menuTo"
        :close-on-content-click="false"
        @input="onMenuToToggle"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formattedTo"
            placeholder="Date to"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            hide-details
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dateToISO"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menuTo = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menuTo.save(dateToISO)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment-timezone'
import { mapState } from 'vuex'

moment.tz.setDefault('Etc/UTC')

export default {
  name: 'ActivatedTablets',
  data: () => {
    return {
      menuFrom: false,
      menuTo: false,
      niceFormat: 'D.M. Y',
      tempNewFromDateISO: '',
      tempNewToDateISO: ''
    }
  },
  computed: {
    ...mapState({
      dateFromTimestamp: state => state.dates.from,
      dateToTimestamp: state => state.dates.to,
      lastSync: state => state.dates.lastSync
    }),
    formattedFrom () {
      return this.dateFromISO ? moment(this.dateFromISO).format(this.niceFormat) : ''
    },
    formattedTo () {
      return this.dateFromISO ? moment(this.dateToISO).format(this.niceFormat) : ''
    },
    dateFromISO: {
      get () {
        return moment(this.dateFromTimestamp * 1000).toISOString()
      },
      set (newDate) {
        this.tempNewFromDateISO = newDate
      }
    },
    dateToISO: {
      get () {
        return moment(this.dateToTimestamp * 1000).toISOString()
      },
      set (newDate) {
        this.tempNewToDateISO = newDate
      }
    }
  },
  methods: {
    onMenuFromToggle (opened) {
      if (!opened) {
        // closing
        this.$store.commit('SET_DATE_FROM', { date: this.tempNewFromDateISO })
      }
    },
    onMenuToToggle (opened) {
      if (!opened) {
        // closing
        this.$store.commit('SET_DATE_TO', { date: this.tempNewToDateISO })
      }
    }
  }
}
</script>
