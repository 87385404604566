import Vue from 'vue'
import axios from 'axios'

export default {
  updateActivatedTablets ({ commit, state }) {
    // const apiUrl = 'https://localhost:3003/api/dashboard/stats/tablets_activated?timestampFrom=' + state.dates.from + timestampTo

    const params = {
      timestampFrom: state.dates?.from,
      timestampTo: state.dates?.to,
      intervalType: state.chartInterval
    }

    commit('SET_LOADING', true)
    axios
      .get(state.API_URL, { params })
      .then(response => {
        commit('SET_ACTIVATED_TABLETS', response.data)
      })
      .catch(error => {
        console.log('Error from SET_LOADING: ', error)
        commit('SET_ERROR', error)
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  },
  getStats ({ commit, state }, payload) {
    const { period, timestampFrom, timestampTo, previousPeriod, description } = payload

    commit('setDescription', { description, period, previousPeriod: previousPeriod })

    commit('setLoadingNumbers', { period, loading: true, previousPeriod: previousPeriod })

    Vue.axios(state.API_URL, { params: { timestampFrom, timestampTo, IntervalType: 'month' } })
      .then(({ data }) => {
        commit('setStats', { period, data, previousPeriod: previousPeriod })
      })
      .catch(error => {
        console.log('Error from getStats: ', error)
      })
      .finally(() => {
        commit('setLoadingNumbers', { period, loading: false, previousPeriod: previousPeriod })
      })
  }
}
